


.backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0; left: 0;
    background: rgba(30, 30, 30, 0.9);
    z-index: 6000;
    overflow: hidden;



    .scores-modal{
        position: absolute;
        bottom: 0;
        height: 80%;
        width: 100%;
        background: black;
        border: 0.5px solid $modal-grey;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        padding: 3rem 2rem 6rem 2rem;


        .modal-inner-container{
            position: relative;
            overflow: hidden;
            border: 0px solid pink;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;

            .modal-top{
                position: relative;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .title-box{
                    .modal-title{
                        font-size: 1.25rem;
                        color: $white-80;
                        margin-bottom: 0.5rem;
                    }
                    .modal-subtitle{
                        font-size: 0.875rem;
                    }
                }

                .modal-close{
                    // position: absolute;
                    // top: 0;
                    // right: 0;
                    height: 2.5rem;
                    width: 2.5rem;
                    border: 1px solid $white-20;
                    border-radius: 0.5rem;
                    display: grid;
                    place-items: center;
    
                    img{
                        height: 1rem;
                        width: 1rem;
                        opacity: (0.8);
                    }
                }
            }



            .chart-container{
                border: 0px solid red;
                padding: 0 0 0rem 0;
                width: 100%;
                height: 100%;
                overflow: hidden;

                #day-half-doughnut{
                    width: 100%;
                    height: 100%;
                    position: relative;   
                    z-index: 30;         
                    cursor: pointer;

                    .watermark-cover{
                        border: 0px solid peru;
                        position: absolute;
                        z-index: 5000;
                        bottom: 0;
                        background: black;
                        width: 100%;
                        height: 2rem;
                    }

                    .score-box{
                        position: absolute;
                        bottom: 42%;
                        left: 50%;
                        transform: translate(-50%, 50%);
                        gap: 0.5rem;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .total{
                            color: $white-50;
                            font-size: 0.875rem;
                        }

                        .day-main-score{
                            font-size: 2rem;
                        }
                    }
                }
            }
            .day-subscores{
                border: 0px solid peru;
                width: 100%;
                height: auto;
                background: black;
                z-index: 5000;

                .subscore-grid{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 1.5rem;


                    .grid-item{
                        border: 0px solid pink;
                        width: 100%;
                        height: 5rem;

                        p{
                            color: $white-50;
                        }
                        .subscore-name{
                            text-transform: capitalize;
                            margin-bottom: 0.75rem;
                        }
                        .subscore-value{
                            color: $white;
                            margin-bottom: 0.5rem;
                        }
                        .subscore-bar-container{
                            height: 0.75rem;
                            border-radius: 0.35rem;
                            width: 100%;
                            background: $white-20;

                            .bar{
                                height: 100%;
                                width: 0;
                                border-radius: 0.35rem;
                                transform-origin: left;
                                // animation: growBar 2s ease-out;
                            }
                        }
                    }
                    .grid-item.health{
                        grid-column: 1 / 2; grid-row: 1;
                        .bar{
                            background: linear-gradient(90deg, #F5C373 -2.08%, #E57F53 100%);
                        }
                    } 
                    .grid-item.wealth{
                        grid-column: 2 / 3; grid-row: 1;
                        .bar{
                            background: linear-gradient(90deg, #CF4A33 -2.08%, #C23D3F 100%);
                        }
                    }
              
                    .grid-item.happiness{
                        grid-column: 3 / 4; grid-row: 1;
                        .bar{
                            background: linear-gradient(90deg, #803242 -2.08%, #6C3961 100%);
                        }
                    } 
                }
            }
            
        }
    }
}


@media (min-width: 1224px) {
  .backdrop{
    z-index: 6000;
    background: rgba(20, 20, 20, 0.95);
    display: grid;
    place-items: center;



    .settings-modal{
        // top: 10%;
        // left: 20%;
        // bottom: none;
        position: relative;
        padding: 1.5rem;
        border-radius: 1.5rem;

        width: 30%;
        height: auto;
        border: 0.5px $white-50;


        .modal-inner-container{
            .modal-btn{
                margin-bottom: 0;
            }
        }
    }
  }
}