
@font-face {
  src: url(../public/fonts/reckless/RecklessNeue-Heavy.ttf);
  font-family: reckless-5;
}
@font-face {
  src: url(../public/fonts/reckless/RecklessNeue-Bold.ttf);
  font-family: reckless-4;
}
@font-face {
  src: url(../public/fonts/reckless/RecklessNeue-SemiBold.ttf);
  font-family: reckless-middle;
}
@font-face {
  src: url(../public/fonts/reckless/RecklessNeue-Regular.ttf);
  font-family: reckless-3;
}
@font-face {
  src: url(../public/fonts/reckless/RecklessNeue-Light.ttf);
  font-family: reckless-2;
}
@font-face {
  src: url(../public/fonts/reckless/RecklessNeue-Thin.ttf);
  font-family: reckless-1;
}



@font-face {
  src: url(../public/fonts/satoshi/Satoshi-Variable.ttf);
  font-family: satoshi;
}



$black: #030303;
$white: #ffffff;
$white-80: rgba(255, 255, 255, 0.8);
$white-50: hsla(0, 0%, 100%, 0.5);
$white-35: hsla(0, 0%, 100%, 0.35);
$white-20: hsla(0, 0%, 100%, 0.2);
$white-10: hsla(0, 0%, 100%, 0.1);
$white-05: hsla(0, 0%, 100%, 0.05);

$dark: #242328;

$button-dark: #242328;
$button-line: #504F53;

$nav-button: #343434;
$nav-text: #CACACA;
$lightmode: #eeeeee;
$click: #8F8F93;
$grey: #636363;

$modal-grey: #B6B6B6;
$modal-white: #FCFCFC;
$modal-black: #1B1B1B;

$health: linear-gradient(90deg, #452c50 0%, #E33A4E 100%);
$wealth: linear-gradient(90deg, #CF283C 0%, #E84519 100%);
$happiness: linear-gradient(90deg, #F26642 0%, #FFC363 100%);
$nodo: #121212;

$health-text: linear-gradient(0deg, #452c50 0%, #E33A4E 75%);
$wealth-text: linear-gradient(0deg, #CF283C 0%, #E84519 100%);
$happiness-text: linear-gradient(0deg, #F26642 0%, #FFC363 100%);



$new-black: rgba(24, 24, 24, 0.8);



*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body{
  width: 100vw;
  background: black;
}

h1, h2{
  font-family: reckless-4;
  font-weight: normal;
  color: white;
}

// stat-title
h4{
  font-family: reckless-2;
  color: $white-50;
  line-height: 1;
}

p{
  font-family: satoshi;
  font-weight: normal;
  color: $white-50;
}

.link{
  text-decoration: none;
}

.satoshi-btn{
  font-family: satoshi;
  font-weight: 400;
  font-size: 1rem;
  color: $white-80;
}


button {
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  font-family: satoshi;
}


.home{
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;

  .link{
    background: darkblue;
    padding: 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
    color: white;
  }
}

.invisible{
  font-size: 0rem;
  display: none;
}

.skeleton{
  background: grey;
  border-radius: 100rem;
}



@import "./styles/Home.scss";
@import "./styles/IntroPage.scss";
@import "./styles/Footer.scss";
@import "./styles/Header.scss";
@import "./styles/TopNav.scss";
@import "./styles/Checkbox.scss";
@import "./styles/Forms.scss";
@import "./styles/Nodo.scss";
@import "./styles/Scores.scss";
@import "./styles/Modal.scss";
@import "./styles/SettingsModal.scss";
@import "./styles/ScoresModal.scss";
@import "./styles/LogReg2.scss";
@import "./styles/Login.scss";
@import "./styles/Dashboard.scss";
@import "./styles/GoalSettings.scss";
@import "./styles/About.scss";



// Desktop Styles
@import "./styles/Desktop/HomeDesktop.scss";
@import "./styles/Desktop/IntroDesktop.scss";
@import "./styles/Desktop/LogRegDesktop.scss";
@import "./styles/Desktop/DashboardDesktop.scss";
@import "./styles/Desktop/Sidebar.scss";
@import "./styles/Desktop/FormsDesktop.scss";
@import "./styles/Desktop/GoalSettingsDesktop.scss";
@import "./styles/Desktop/TopNavDesktop.scss";
@import "./styles/Desktop/PageTop.scss";
@import "./styles/Desktop/Carousel.scss";
