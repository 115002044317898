
.logreg-switch{
    padding: 0 1.5rem;
}
.switch{
    position: relative;
    margin-top: 5rem;
    background: $nav-button;
    border-radius: 1rem;
    height: 3.6rem;
    padding: 0.3rem;
    width: 100%;
    border: 0px solid red;

    display: flex;
    align-items: center;

    .handle{
        background: black;
        height: 100%;
        width: 50%;
        border-radius: 1rem;
        color: white;

        display: grid;
        place-items: center;
    }

    .top-switch{
        position: absolute;
        z-index: 20;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        border: 0px solid purple;

        display: flex;
    }

    .top-switch-text{
        flex: 1;
        color: white;
        display: grid;
        place-items: center;
    }
}