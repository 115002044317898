

.footer{
    border: 0px solid white;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;

    padding: 1.5rem;
    display: flex;
    justify-content: space-between;


    .close, .next, .back{
        width: 3.5rem;
        height: 3.5rem;
        background: none;
        border-radius: 1rem;
        border: 1px solid white;
    }
    .none{
        width: 3.5rem;
        height: 3.5rem;
    }

    .next{
        background: white;
    }

    .close{
        background: black;
    }

    .iconarrow, .iconclose{
        width: 100%;
        height: 100%;

        display: grid;
        place-items: center;
    }
}

