

.homePage{
    width: 100vw;
    height: 100%;
    background: black;

    .home-container{
        // testing
        position: fixed;
        //
        border: 0px solid white;
        // position: relative;

        height: 60vh;
        width: 100vw;
        background: url(../../public/videos/moonanim.mp4);
        background-size: cover;
        background-position: center;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3rem 1.5rem;

        video{
            position: absolute;
            z-index: 10;
            top: 30%;
            left: -5%;;

            height: 21rem;
            width: 21rem;
        }

        h6{
            color: white;
        }

        .home-welcome{
            position: absolute;
            z-index: 10;
            top: 15%;
            font-size: 2.5rem;
            font-family: reckless-1;
            color: $white-50;
            align-self: flex-start;
            
            span{
                color: $white-80;
                font-family: reckless-4;
            }
        }

        .home-score{
            position: absolute;
            z-index: 10;
            bottom: 10%;
            right: 1.5rem;
        }
        .home-score-container{
            border: 0px solid red;
            align-self: flex-end;
            height: 7rem;
            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .big-score{
                border: 0px solid green;
                display: flex;
                margin-left: 1.5rem;
            }

            h2{
                color: white;
                font-size: 3.2rem;
                margin-bottom: 0.6rem;
            }
            .score-descrip{
                color: $white-80;
                font-family: satoshi;
                letter-spacing: 0.02rem;
                font-weight: 300;
            }
            .score-change{
                font-family: reckless-1;
                font-size: 0.8rem;
                color: $white-80;
            }
        }
    }


    .home-cards{
        border: 0px solid orange;
        background: black;
        width: 100%;
        height: auto;
        // testing
        padding: 60vh 1.5rem;
        // padding-bottom: 3rem;

        .mini-cards{
            position: relative;
            z-index: 11;
            margin-top: 2rem;
            display: flex;
            gap: 1.5rem;
        }
        .home-card{
            position: relative;
            z-index: 10;
            border: 1px solid $white-20;
            height: auto;
            border-radius: 1rem;
            padding: 3rem 1.5rem;
            box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(10px);

            display: flex;
            justify-content: center;
            margin-bottom: 1.5rem;


            .home-card-left{
                border: 0px solid blue;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin-right: 1.5rem;

                p{
                    font-weight: 300;
                }
            }
            .home-card-right{
                border: 0px solid salmon;
                display: grid;
                place-items: center;

                .home-card-icon{
                    border: 1px solid $button-line;
                    background: none;

                    height: 60px;
                    width: 60px;
                    border-radius: 1rem;
                    
                    display: grid;
                    place-items: center;
                }
            }

            .home-card-title{
                font-family: reckless-4;
                letter-spacing: 0.05rem;
                text-transform: capitalize;
                color: white;
            }

            .home-card-text{
                color: $white-50;
            }
        }
        #newlog{
            background: linear-gradient(114.83deg, rgba(255, 255, 255, 0.15) 5.95%, rgba(255, 255, 255, 0.07) 93.77%);
        }

        #checkscores{
            background: linear-gradient(114.83deg, hsla(0, 0%, 100%, 0.1) 5.95%, hsla(0, 0%, 100%, 0.05) 93.77%);
        }

        .btn-logout{
            width: 100%;
            height: 3.5rem;
            border-radius: 1rem;
            border: 1px solid $white-20;
            color: $modal-grey;

            transition: 1s;
        }

        .btn-logout:hover{
            border: 1px solid $white;
        }
    }
}