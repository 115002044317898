
.backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0; left: 0;
    background: rgba(30, 30, 30, 0.9);
    z-index: 6000;



    .settings-modal{
        position: absolute;
        bottom: 0;
        height: 80%;
        width: 100%;
        background: black;
        border: 0.5px solid $modal-grey;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        padding: 1.5rem 1.5rem 3rem 1.5rem;


        .modal-inner-container{
            position: relative;
            border: 0px solid red;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;

            .modal-close{
                position: absolute;
                top: 0;
                right: 0;
            }

            .modal-icon{
                margin-top: 3rem;
                margin-bottom: 1.5rem;
            }

            .modal-title{
                font-size: 2rem;
                color: white;
                font-family: reckless-middle;
                margin-bottom: 3rem;
            }

            .modal-text{
                color: $modal-grey;
                line-height: 1.9rem;
                margin-bottom: 3rem;
            }

            .user-box{
                width: 100%;
                padding: 1rem;
                border-bottom: 1px solid $white-20;
                border-top: 1px solid $white-20;

                display: flex;

                .user-pic{
                    height: 3.5rem;
                    width: 3.5rem;
                    
                    border-radius: 3rem;
                    background: #131313;
                    display: grid;
                    place-items: center;
                    margin-right: 1.5rem;

                    color: $white-50;
                    font-family: satoshi;
                    font-size: 1.5rem;
                    font-weight: 300;
                }

                .user-info{
                    border: 0px solid peru;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .user-name, .user-mail{
                        font-family: satoshi;
                        font-weight: 300;
                        font-size: 1.1rem;
                        color: $white-50;
                    }
                }
            }

            .menu-items{
                width: 100%;
                padding: 1rem 0;
                border-bottom: 1px solid $white-20;
                margin-bottom: 1.5rem;
                .item{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 1rem 1.25rem;
                    border-radius: 0.5rem;

                    .icon-container{
                        margin: 0;
                        padding: 0;
                        margin-right: 1rem;
                        border: 0px solid peru;
                        
                        .random-icon svg{
                            margin: 0;
                            padding: 0;
                            max-height: 1.5rem;
                            width: 1.5rem;
                        }
                    }

                    .text{
                        color: $white-50;
                        font-family: satoshi;
                        border: 0spx solid purple;
                        padding-bottom: 0.2rem;
                    }
                }
                .item:hover{
                    background: #131313;
                }
            }

            button{
                height: 3.5rem;
                width: 100%;
                border: 1px solid $white-20;
                border-radius: 0.5rem;
                margin-bottom: 1.5rem;
                text-transform: lowercase;
            }
            .modal-btn{
                background: none;
                color: $modal-grey;
                text-transform: capitalize;
            }
            
        }
    }
}


@media (min-width: 1224px) {
  .backdrop{
    z-index: 6000;
    background: rgba(20, 20, 20, 0.95);
    display: grid;
    place-items: center;



    .settings-modal{
        // top: 10%;
        // left: 20%;
        // bottom: none;
        position: relative;
        padding: 1.5rem;
        border-radius: 1.5rem;

        width: 30%;
        height: auto;
        border: 0.5px $white-50;


        .modal-inner-container{
            .modal-btn{
                margin-bottom: 0;
            }
        }
    }
  }
}