

.home-desktop {
    position: relative;
    height: 100%;

  
    .topbar {
      position: fixed;
      z-index: 20;
      top: 0;
      height: 8rem;
      width: 100%;
      padding: 0 10%;
    //   background: rgba(3, 3, 3, 0.8);
      background: linear-gradient(180deg, rgba(3, 3, 3, 0.8) 0.52%, rgba(3, 3, 3, 0.8) 43.23%, rgba(3, 3, 3, 0) 100%);
      display: flex;
      justify-content: flex-start;
      align-items: center;
  
      .logo-block {
        color: white;
        font-family: reckless-4;
        font-size: 2rem;
      }
      .logo-block2{
        .random-icon svg{
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
            opacity: 0.5;
        }
      }
    }
    
    .home-main{
        width: 100%;
        height: 100%;
        display: flex;

        .home-static{
            position: fixed;
            flex: 1;
            height: 100%;
            width: 100%;

            
            video{
                margin-top: -10%;
                width: 100%;
                padding: 0 35% 0% 20%;
            }
        }
        .home-absolute{
            margin-top: 7rem;
            width: 100%;
            display: flex;

            .empty-scroll{
                flex: 1;
                width: 100%;
            }

            .static-container{
                position: fixed;
                flex: 1;
                width: 100%;
                height: calc(100vh - 7rem);


                .home-welcome{
                    position: absolute;
                    top: 0%; left: 10%;
                    z-index: 10;
                    font-size: 6rem;
                    font-family: reckless-1;
                    color: $white;
                    border: 0px solid green;

                    span{
                        margin-top: -5%;
                        display: block;
                        color: $white;
                        font-family: reckless-middle;
                    }
                }

                .stats-container{
                    position: absolute;
                    bottom: 10%; left: 10%;
                    z-index: 10;
                    width: 35%;
                    color: white;

                    display: flex;
                    border: 0px solid green;
                    
                    .stats-left{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 2.5rem;

                        div{
                            height: 100%
                        }
                        .stats-total{
                            .stat-value{
                                color: $white;
                                margin: 0;
                                font-family: reckless-middle;
                                font-size: 4rem;
                            }
                        }
                        .stats-change{
                            .stat-value{
                                color: $white;
                                font-family: reckless-middle;
                                font-size: 1.5rem;
                                display: flex;
                                div{
                                    color: $white;
                                    font-family: reckless-middle;
                                    font-size: 1.5rem;
                                }   
                            }
                        }
                    }
                    .stats-right{
                        width: 100%;
                        border: 0px solid red;

                        .sub-score-container{
                            height: 100%;
                            display: flex;
                            flex-direction: column;

                            .stat-title{
                                margin-bottom: 1.5rem;
                            }

                            .title-values{
                                height: 100%;
                                display: flex;
                                gap: 1rem;

                                div{
                                    border: 0px solid peru;
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
    
                                    .sub-score-value{
                                        color: $white;
                                        font-family: reckless-middle;
                                    }
    
    
                                    .sub-score-title{
                                        font-family: reckless-2;
                                    }
                                }
                            }
                        }
                    }
                    .stat-title{
                        border: 0px solid purple;
                        margin-bottom: 1rem;
                    }
                }
            }

            .scrollable-container{
                box-sizing: border-box;
                flex: 1;
                height: 100%;
                width: 100%;
                padding: 15% 0;
                
                
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
   

                .card-container{
                    width: 100%;
                    padding: 0 5rem;

                    .home-card{
                        position: relative;
                        z-index: 10;
                        border: 1px solid $white-20;
                        height: 15rem;
                        width: 100%;
                        border-radius: 1.5rem;
                        padding: 3rem 1.5rem;
                        margin-bottom: 3rem;
                        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
                        backdrop-filter: blur(10px);
                        
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                    }

                    .home-card.two{
                        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.025);
                        backdrop-filter: blur(20px);
                    }

                    // .home-card.three{
                    //     background: linear-gradient(114.83deg, rgba(175, 175, 175, 0.08) 5.95%, rgba(174, 174, 174, 0.04) 93.77%);
                    //     border: 1px solid rgba(255, 255, 255, 0.2);
                    //     box-shadow: 0px 20px 40px rgba(255, 255, 255, 0.05);
                    //     backdrop-filter: blur(10px);
                    // }

                    .mini-cards{
                        position: relative;
                        z-index: 10;
                        height: 5rem;
                        width: 100%;
                        display: flex;
                        gap: 2rem;

                        div{
                            height: 100%;
                            width: 100%;
                            flex: 1;
                            border-radius: 1rem;
                            border: 1px solid $white-20;
                            box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
                            backdrop-filter: blur(10px);
                        }
                    }

                    .home-cards{
                        padding: 1.5rem;
                        .home-card{
                            padding: 3rem 3rem;
                            justify-content: space-between;
                            transition: 1s;
                            .home-card-left{
                                justify-content: center;
                            }
                        }
                    }

                }
            }
        }

        .home-absolute::-webkit-scrollbar {
            display: none;
        }
    }
}
  