


.about-page{
    width: 100%;
    height: 100vw;
    padding: 1.5rem;

    .topbar {
        position: fixed;
        z-index: 20;
        top: 0;
        height: 8rem;
        width: 100%;
        padding: 0 0%;
        background: linear-gradient(180deg, rgba(3, 3, 3, 0.8) 0.52%, rgba(3, 3, 3, 0.8) 43.23%, rgba(3, 3, 3, 0) 100%);
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        .logo-block {
          color: white;
          font-family: reckless-4;
          font-size: 2rem;
        }
        .logo-block2{
          .random-icon svg{
              width: 1.5rem;
              height: 1.5rem;
              cursor: pointer;
              opacity: 0.5;
          }
        }
      }
    .about-top{
        padding-top: 8rem;

        h2{
            color: $white-80;
            font-size: 2rem;
            font-family: reckless-middle;
            margin-bottom: 1rem;
        }
        p{
            color: $white-50;
        }
    }
    .main{
        padding-bottom: 1.5rem;
    }

    .info-blob{
        padding: 8rem 0;

        .blob-item{
            margin-bottom: 8rem;
            h3{
                margin-bottom: 2rem;
                font-family: satoshi;
                font-weight: 400;
                font-size: 1.25rem;
                text-transform: capitalize;
                color: $white;
            }
            p{
                color: $white-50;
                line-height: 2rem;
            }
        }
    }

    .card-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;

        .card{
            text-decoration: none;
            width: 100%;
            height: 100%;
            flex: 1;
            padding: 2rem;
            border: 1px solid $white-20;
            border-radius: 1rem;

            .icon-container{
                border: 1px solid $white-20;
                height: 3rem;
                width: 3rem;
                border-radius: 0.5rem;
                display: grid;
                place-items: center;
                margin-bottom: 1rem;

                .random-icon svg{
                    width: 1.4rem;
                    height: auto;
                }
            }

            .card-title{
                font-family: satoshi;
                font-size: 1rem;
                font-weight: 200;
                color: $white-50;
                margin-bottom: 1rem;
            }

            .card-value{
                font-family: satoshi;
                font-size: 2rem;
                font-weight: 400;
                color: $white-80;
            }
        }
    }
}


@media only screen and (min-width: 1200px) {
    .about-page{
        padding: 0 10%; 
        .page-top{
            padding-top: 4rem;
            h2{
                font-family: reckless-middle;
                font-size: 3rem;
            }
        }

        .main{
            margin-top: 30%;
            display: flex;


            .info-blob, .card-container{
                flex: 1;
                width: 100%;
                height: 100%;

            }

            .info-blob{
                padding-top: 0;

                .blob-item{
                    margin-bottom: 20%;
                }
            }
            .card-container{
                // padding: 0 0 0 25%;
                justify-content: center;
                align-items: flex-end;
                gap: 3rem;

                .card{
                    width: 60%;
                }
            }

        }
    }
}