

.goalsettings{
    position: relative;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    border: 0px solid salmon;
    background: #121212;
    display: flex;


    // .sidebar{}
    // .pla

    .top-top{
        position: fixed;
        z-index: 101;
        background: black;
        width: 100vw;
        overflow-x: hidden;
        justify-content: center;
        align-items: center;
        // margin-left: 0.25rem;
    }
    .main{
        width: 100%;
    }

    .header{
        position: relative;
    }
    .scroll-progress{
        height: 3px;
        width: 100%;
        background: $white-20;
        

        .progress-bar {
            height: 100%;
            background-color: $white-20;
            width: 0%;
            transition: width 0.01s ease-in-out, background-color 0.5s ease-in-out;
        }
    }

    .invisible-top{
        width: 100%;
        height: 120px;
    }
    .section{
        min-height: 75vh;
        height: auto;
        width: 100%;
        padding: 14rem 1.5rem 0rem 1.5rem;
        border: 0px solid pink;

        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;


        .section-top{
            border: 0px solid peru;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.75rem;
            margin-bottom: 2rem;

            h2{
                font-size: 1.5rem;
                font-family: reckless-middle;
                text-transform: capitalize;
                color: $white-80;
            }

            .section-number{
                font-size: 0.75rem;
            }
        }


        .actives-box, .inactives-box{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info-box{
                align-self: flex-end;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                .circle{
                    height: 0.5rem;
                    width: 0.5rem;
                    border-radius: 1rem;

                }
                p{
                    font-size: 0.875rem;
                    font-weight: 200;
                }
            }
        }
        .actives-box .info-box .circle{
            background: $white-10;
            animation: pulse 3s infinite ease-in-out;
        }
        .inactives-box .info-box .circle{
            background: black;
            border: 0.2px solid $white-50;
        }
        @keyframes pulse {
            0%, 100% {
                box-shadow: 0 0 4px rgba(255, 255, 255, 0.1);
                transform: scale(1);
                background: $white-10;
            }
            50% {
                box-shadow: 0 0 8px rgba(255, 255, 255, 0.9);
                transform: scale(1.2);
                background: $white-80;
            }
        }
        

        .section-container{
            width: 100%;
            height: auto;
            padding: 1rem 0;
            border: 0px solid $white-20;

            .accordion-header.active{
                border: 1px solid $white-35;
            }
            .accordion-header{
                border: 0px solid purple;
                max-height: 5rem;
                min-width: 100%;
                padding: 1.5rem;
                border-radius: 0.5rem;
                border: 1px solid $white-20;
                background: $black;

                display: flex;
                justify-content: flex-start;
                align-items: center;

                .checkbox{
                    height: auto;
                    width: 2rem;
                    margin-right: 1.5rem;

                    input{
                        height: 100%;
                        width: 100%;
                        border: 1px solid peru;
                    }
                }


                h3.title{
                    font-family: satoshi;
                    font-size: 1rem;
                    color: $white;
                    margin-right: 1rem;
                }
                .miniscores-container{
                    margin-right: 0.5rem;
                    border: 0px solid pink;
                    width: 100%;
                    overflow: hidden;
                    display: flex;

                    .mini-score{
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .mini-title{
                            white-space: nowrap;
                            overflow: hidden;
                            font-family: satoshi;
                            font-weight: 300;
                            color: $white-50;
                        }
                        .mini-value{
                            font-size: 0.65rem;
                            font-family: satoshi;
                            font-weight: 300;
                            color: $white-50;
                            padding: 0.25rem;
                            white-space: nowrap;
                            overflow: hidden;
                            border: 0.5px solid $white-50;
                            border-radius: 2px;
                        }
                        .dot{
                            background: $white-80;
                            height: 5px;
                            width: 5px;
                            border-radius: 4px;
                            margin: 0 0.75rem;
                        }
                    }
                }

                .icon-container{
                    margin-left: auto;
                    justify-self: flex-end;
                    transition: transform 0.3s ease-in-out;
                    cursor: pointer;

                    svg{
                        width: auto;
                        height: 1rem;
                    }
                }
                .icon-container.is-rotating{
                    transform: rotate(90deg);
                    transition: transform 0.3s ease-in-out;
                }
            }

            .accordion-content{
                width: 100%;
                background: #131313;
                border-left: 1px solid $white-20;
                border-right: 1px solid $white-20;
                border-bottom: 1px solid $white-20;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;

                .accordion-inner-content{
                    position: relative;
                    height: 40rem;
                    padding: 2rem 1.5rem;

                    .bin-icon{
                        position: absolute;
                        top: 2rem;
                        right: 1.5rem;
                        cursor: pointer;
                    }
                    .bin-icon .random-icon svg{
                        height: 1rem;
                        width: 1rem;
                    }

                    h3{
                        font-family: reckless-middle;
                        margin-bottom: 1.5rem;
                    }
                    p.content-text{
                        font-size: 0.75rem;
                        margin-bottom: 3rem;
                    }

                    .value-box{
                        position: relative;
                        border: 1px solid $white-20;
                        border-radius: 0.5rem;
                        height: 16rem;
                        overflow: hidden;

                        .topbar{
                            margin-bottom: 0rem;
                            display: flex;
                            background: black;
                            border-bottom: 1px solid $white-20;

                            .topbar-title{
                                padding: 1rem 1.25rem;
                                flex: 1;
                                font-family: satoshi;
                                font-weight: 300;
                                font-size: 0.875rem;
                                color: $white-80;
                            }
                        }
                        .activity, .total-activity{
                            position: relative;
                            width: 100%;
                            display: flex;
                            

                            p{
                                padding: 0.5rem 1.25rem;
                                width: 100%;
                                flex: 1;
                            }
                            .bin{
                                position: absolute;
                                top: 0.75rem;
                                bottom: 0.75rem;
                                right: 1rem;
                                opacity: 0;
                                cursor: pointer;
                            }
                            .bin .random-icon svg{
                                height: 1rem;
                                width: 1rem;
                            }
                        }
                        .activity:first-child{
                            padding-top: 1rem;
                        }
                        .total-activity{
                            position: absolute;
                            bottom: 1rem;
                            background: #121212;
                        }
                        .activity:hover{
                            background: black;
                            .bin{
                                opacity: 1;
                            }
                        }
                    }
                    .settings-form, .settings-form-right{
                        position: absolute;
                        bottom: 3rem;
                        right: 1.5rem; left: 1.5rem;

                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto;
                        gap: 1.5rem;

                        label{
                            font-family: satoshi;
                            font-size: 0.75rem;
                            font-weight: 300;
                            color: $white-80;

                            
                        }
                        input{
                            margin-top: 0.75rem;
                            width: 100%;
                            height: 3rem;
                            background: black;
                            color: $white;
                            border-radius: 0.25rem;
                            border: none;
                            box-shadow: none;
                            border: 0.5px solid $white-20;
                            text-indent: 1rem;

                            &::placeholder{
                                font-family: satoshi;
                                font-size: 0.75rem;
                                color: $white-20;
                            }
                            &:focus{
                                outline: none;
                                border: 1px solid $white-80;
                            }
                        }



                        .add-btn{
                            grid-column: 1 / -1;
                            height: 3rem;
                            font-size: 0.875rem;
                            border-radius: 0.25rem;
                            border: 1px solid $white-50;
                            display: grid;
                            place-items: center;
                            cursor: pointer;
                            transition: 0.3s ease-in-out;
                        }
                        .add-btn:hover, .add-btn.active{
    
                            background: $white-80;
                            color: $dark;
                            font-weight: 500;
                        }
                        .error-message{
                            position: absolute;
                            bottom: -2rem;
                            font-size: 0.75rem;
                            font-weight: 200;
                            color: $white-80;
                        }
                    }
                }
            }
            .accordion-content.slider-accordion{
                .accordion-inner-content{
                    height: 54rem;

                    .value-box{
                        height: auto;
                        
                        .display-content{
                            border: 0px solid peru;
            
                            padding: 0 1.5rem 2rem 1.5rem;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;                   

                            .unit-value{
                                height: auto;
                                margin-top: 2rem;
                                border: 0px solid green;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                                gap: 2rem;
                                h1.value{
                                    border: 0px solid purple;
                                    color: $white-50;
                                    font-size: 3rem;
                                    margin: 0;
                                    padding: 0;
                                    line-height: 1;
                                    letter-spacing: 2px;
                                }
                                p.unit{
                                    border: 0px solid cyan;
                                    color: $white-50;
                                    margin: 0;
                                    font-size: 0.875rem;
                                }
                            }
                            .border-line{
                                width: 3rem;
                                background: $white-20;
                                height: 1px;
                                margin-bottom: 2rem;
                                margin-top: 0.5rem;
                            }

                            .slider-container{
                                width: 100%;
                                padding: 0 0.5rem;
                                margin-bottom: 2rem;
                                .the-slider{
                                    width: 100%;
                                    height: 4px;
                                    border-radius: 2px;
                                    background: black;
                                    -webkit-appearance: none; 
                                    appearance: none;
                    
                                    --sliderValue: 0%;
                                    background: linear-gradient(to right, white, pink var(--sliderValue), grey var(--sliderValue));
                                }
                    
                                .the-slider::-webkit-slider-runnable-track{
                                    -webkit-appearence: none;
                                    height: 4px;
                                }
                    
                                .the-slider::-moz-track{
                                    -moz-appearance: none;
                                    height: 4px;
                                }
                    
                                .the-slider::-ms-track{
                                    appearance: none;
                                    height: 4px;
                                }

                                .the-slider::-webkit-slider-thumb {
                                    -webkit-appearance: none;
                                    appearance: none;
                                    width: 0.75rem;
                                    height: 1.75rem;
                                    background: black;
                                    border-radius: 4px;
                                    border: 1px solid $white-20;
                                    cursor: pointer;
                                    margin-top: -10px;
                                }
                                
                                .the-slider::-moz-range-thumb {
                                    width: 10px;
                                    height: 25px;
                                    background: pink;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    margin-top: -10px;
                                }
                            }
                        
                            .value-displays, .bottom-content{
                                width: 100%;
                                display: flex;
                                justify-content: space-between;

                                .min-display, .max-display{
                                    background: black;
                                    border: 1px solid $white-20;
                                    padding: 0.5rem 1rem;
                                    border-radius: 0.5rem;

                                    p{
                                        font-size: 0.875rem;
                                    }
                                }
                            }
                        }
                    }
                    .settings-form{

                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto auto auto;
                        gap: 1.5rem;
                    }
                }
            }
        }
        .accordion-container{
            width: 100%;
            height: auto;
            padding: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;
            div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.75rem;
                cursor: pointer;

                h4{
                    font-size: 0.8rem;
                    font-family: satoshi;
                    font-weight: 300;
                    color: $white-50;
                }
            }
            .add-accordion{
                height: 3.5rem;
                width: 3.5rem;
                border-radius: 1rem;
                border: 1px solid $button-line;
                background: black;
                animation: pulse 3s linear infinite;
                transition: box-shadow 0.3s ease, border-color 0.3s ease, transform 0.3s ease;

                display: grid;
                place-items: center;
                font-size: 2rem;

                .random-icon svg{
                    width: 1rem;
                    height: 1rem;
                    opacity: 0.5;
                }
            }
            .add-slider .add-accordion .random-icon svg{
                width: 1.25rem;
                height: 1.25rem;
            }
            @keyframes pulse {
            
                0% {
                transform: scale(1);
                box-shadow: 0 0 0 0 $button-line;
                border-color: $button-line;
                }
                50% {
                transform: scale(1.02);
                box-shadow: 0 0 8px 0 $button-line;
                border-color: lighten($button-line, 5%);
                }
                100% {
                transform: scale(1);
                box-shadow: 0 0 0 0 $button-line;
                border-color: $button-line;
                }
            }
        }
    }

    .save-container{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 1.5rem;
        width: auto;
        height: auto;
        // border: 1px solid green;

        .info-message{
            display: flex;
            gap: 0.5rem;
            margin-bottom: 1.5rem;
            display: none;

            .text{
                color: $white-80;
                font-family: satoshi;
                font-size: 0.875rem;
                font-weight: 200;
            }

            .icon{
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 1rem;
                background: $white;
                color: $dark;
                display: grid;
                place-items: center;
                font-size: 1rem;
            }
        }

        .save-btn{
            margin-left: auto;
            height: 3rem;
            width: 8rem;
            margin-bottom: 0.5rem;
            display: grid;
            place-items: center;
            border: 1px solid $white-20;
            background: $black;
            border-radius: 0.5rem;
            font-family: satoshi;
            font-weight: 400;
            font-size: 1rem;
            text-transform: lowercase;
            transition: 0.3s ease-in-out;
            color: $white-50;
            cursor: pointer;


        }

        .save-btn:hover{
            background: $white;
            color: $black;
            border: 1px solid $white;
        }
        
        .message{
            font-size: 0.875rem;
            color: $white-50;
        }
    }

}



