

.logreg-desktop{
    position: fixed;
    z-index: 6000;
    display: grid;
    place-items: center;
    background: black;
    height: 100vh;
    width: 100vw;

    .logreg-background{
        position: relative;
        height: 100%;
        width: 100%;

        // background: url(../../../public/images/bg-woman-whole.jpg);
        background: url(../../../public/images/hhh-cut.webp);
        background-size: cover;
        background-position: 0% 45%;
        display: flex;
        justify-content: center;
        align-items: center;

        .logreg-card{
            position: absolute;
            left: 10%;
            width: 490px;
            height: 600px;
            border-radius: 1rem;
            border: 1px solid $white-20;

            // box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
            // backdrop-filter: blur(10px);
            // background: linear-gradient(114.83deg, rgba(255, 255, 255, 0.05) 5.95%, rgba(255, 255, 255, 0.07) 93.77%);
            display: none;
        }


        .logreg-card2{
            position: absolute;
            left: 10%;
            width: 490px;
            height: auto;
            border-radius: 1rem;
            border: 1px solid $white-20;

            box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(10px);
            padding: 2.5rem;
            // display: none;
        }
        .logo-card{
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-top: 5%;
            padding-right: 5%;

            p{
                font-family: satoshi;
                color: $white-50;
            }

            .glitch-solid h1{
                color: $white;
                font-family: reckless-4;
                font-size: 4rem;
            }
            
              
        }
    }
}


.login-desktop{
    width: 100%;
    height: 100%;
    border: 0px solid blue;

    .logreg-title{
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .logreg-toggle{
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: end;

        border: 0px solid peru;
        gap: 0.5rem;

        p{
            color: $white-50;
            font-size: 0.8rem;
        }
        p.toggle-switch{
            color: $white;
            transition: 0.3s ease-in-out;
            // color: $white-80;
        }
        p.toggle-switch:hover{
            color: orangered;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}


.glitch {
    color: $white;
    position: relative;
    font-family: reckless-4;
    font-size: 4rem;
    // margin: 70px 200px;
    animation: glitch 5s 5s infinite;
  }
  
  .glitch::before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: -5px 0 lightgray;
    // background: black;
    overflow: hidden;
    top: 0;
    animation: noise-1 3s linear infinite alternate-reverse, glitch 5s 5.05s infinite;
  }
  
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -5px 0 orangered;
    // background: black;
    overflow: hidden;
    top: 0;
    animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
  }
  
  @keyframes glitch {
    1%{
      transform: rotateX(10deg) skewX(90deg);
    }
    2%{
      transform: rotateX(0deg) skewX(0deg);
    }
  }
  
  @keyframes noise-1 {
    $steps: 30;
    @for $i from 1 through $steps {
      #{percentage($i*(1/$steps))} {
        $top: random(100);
        $bottom: random(101 - $top);
        clip-path: inset(#{$top}px 0 #{$bottom}px 0);
      }
    }
  }
  
  @keyframes noise-2 {
    $steps: 30;
    @for $i from 0 through $steps {
      #{percentage($i*(1/$steps))} {
        $top: random(100);
        $bottom: random(101 - $top);
        clip-path: inset(#{$top}px 0 #{$bottom}px 0);
      }
    }
  }
  
  .scanlines {
    overflow: hidden;
    mix-blend-mode: difference;
  }
  
  .scanlines::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  
    background: repeating-linear-gradient(
      to bottom,
      transparent 0%,
      rgba(255, 255, 255, 0.05) .5%,
      transparent 1%
    );
  
    animation: fudge 7s ease-in-out alternate infinite;
  }
  
  
  @keyframes fudge {
    from {
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(0px, 2%);
    }
  }
  
  .glow {
    @extend .glitch;
    text-shadow: 0 0 1000px rgb(223, 191, 191);
    color: transparent;
    position: absolute;
    top: 0;
  }
  
  
  @keyframes glitch-2 {
    1%{
      transform: rotateX(10deg) skewX(70deg);
    }
    2%{
      transform: rotateX(0deg) skewX(0deg);
    }
  }



.loginForm, .registerForm{
    .button-block{
        button{
            border-radius: 0.5rem;
        }
    }
}