

.top-progress {
    position: relative;
    width: 100%;
    z-index: 100;
    background: $black;
    height: 5rem;

    .top-container {
        position: relative;
        width: 100%;
        height: 5rem;
        padding: 0.5rem 3rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 3rem;


        // .health {
        //     margin-left: 6rem;
        // }
        // .nodo {
        //     margin-right: 20rem;
        // }

        .top-element {
            flex: 1;
            padding: 1rem;
            color: $white-20;
            cursor: pointer;
            height: 100%;
            width: auto;
            border-radius: 0.5rem;
            border: 0px solid $white-20;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;

            .number, .name {
                transition: color 0.15s ease-in-out;
            }

            .number {
                display: grid;
                place-items: center;

                .random-icon svg{
                    width: 1rem;
                    height: auto;
                    vertical-align: middle;
                }
                .random-icon.stroke svg path{
                    stroke: $white-20;
                    transition: fill 0.15s ease-in-out, stroke 0.15s ease-in-out;
                }
                .random-icon.fill svg path{
                    fill: $white-20;
                    transition: fill 0.15s ease-in-out, stroke 0.15s ease-in-out;
                }
            }

            .name {
                font-family: satoshi;
                font-size: 1rem;
                font-weight: 300;
                vertical-align: middle;
            }

            &:hover, &.active {
                color: $white-80;

                .number .random-icon.stroke svg path {
                    stroke: $white-80;
                }
                .number .random-icon.fill svg path {
                    fill: $white-80;
                }

                .name {
                    color: $white-80;
                }
            }

            // &.nodo {
            //     margin-right: 6rem;
            // }
        }

        .progress-line {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: $white-20;
            transition: width 0.5s cubic-bezier(0.19, 1, 0.22, 1), background-color 0.5s ease-in-out;
        }
    }
}


// nodo problem
.top-progress .top-container{
    .top-element.nodo{
        .name{
            color: $white-20;
        }
    }
    .top-element.nodo.active{
        .name{
            color: $white-80;
        }
    }
    .top-element.nodo:hover{
        .name{
            color: $white-80;
        }
    }
}


// happiness problem
.top-progress .top-container{
    .top-element.happiness{

        .number .random-icon.stroke svg path.eyes {
            fill: $white-20;
        }
    }
}


.top-progress .top-container{
    .top-element.happiness.active{
        .number .random-icon.stroke svg path.eyes {
            stroke: $white-80;
            fill: $white-80;
        }
    }
}


.top-progress .top-container{
    .top-element.happiness:hover{
        .number .random-icon.stroke svg path.eyes {
            stroke: $white-80;
            fill: $white-80;
        }
    }
}