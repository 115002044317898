.header{
    display: grid;
    place-items: center;
    padding: 0 2rem;

    h2{
        font-size: 2rem;
        padding: 2rem 0 1.5rem 0;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $white-20;
    }
}