

.feedback-page{
    position: relative;
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1.5rem;
    color: $white;

    .big-box{
        width: 100%;
        padding: 2.5rem;
        margin-bottom: 1rem;
        border: 1px solid $white-20;
        border-radius: 1rem;


        .svg-container{
            width: 100%;
            display: grid;
            place-items: center;
            margin-bottom: 2.5rem;
        }


        .sub-score-list{
            width: 100%;
            
            .sub-score-item{
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 1.5rem;

                .item-title{
                    font-weight: 300;
                    color: $white-50;
                }
            }
        }


    }
    .small-box{
        width: 100%;
        padding: 1.5rem 0;
        display: grid;
        place-items: center;
        border: 1px solid $white-20;
        border-radius: 1rem;
    }
    .total-box{
        padding: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .total-score{
            font-size: 3rem;
        }

        .total-text{
            max-width: 60%;
            text-align: center;
            line-height: 1.5rem;
            font-size: 0.75rem;
            font-family: satoshi;
            font-weight: 300;
            color: $white-50;
        }
    }
    .lottie-wrapper{
        position: absolute;
        top: 0; bottom: 10%;
        right: 0; left: 0;
    }
    .next{
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        width: 3.5rem;
        height: 3.5rem;
        background: none;
        border-radius: 1rem;
        background: $white;
        border: 1px solid white;
    }
}


@media only screen and (min-width: 1200px) {
    .feedback-page{
        overflow: hidden;
        align-items: flex-start;
        padding: 10%;
        padding-top: 12.5%;
        padding-left: 12.5%;

        .moon{
            width: 100%;
            height: 100%;
            video{
                position: absolute;
                z-index: 10;
                top: -25%;
                right: 7%;;
    
                height: 40rem;
                width: 40rem;
            }
        }

        .big-box, .small-box, .total-box{
            z-index: 20;
            max-width: 32.5%;
            // border: 1px solid peru;
        }

        

        .total-box{
            width: 100%;

            .total-text{
                font-size: 0.875rem;
            }
        }
    }
    .lottie-wrapper{
        z-index: 21;
        padding: 0 10rem 0 10rem;
    }
  }