

.dashboard-desktop{
    width: 100vw;
    height: 100%;
    display: flex;


    .placeholder-sidebar{
        position: relative;
        height: 100vh;
        padding: 1rem 0;
        border: 0px solid peru;
    }
    .dashboard-main{
        border: 0px solid orange;
        width: 100%;
        display: grid;
        place-items: center;
        color: white;

        .mainview{
            width: 100%;
            height: 100%;
            padding: 4rem 1.5rem 1.5rem 3rem;
            display: flex;
            gap: 1.5rem;

            .main-center{
                border: 0px solid peru;
                width: 100%;
                height: 100%;
                flex: 3;
    

                .top-row{
                    border: 0px solid red;
                    display: flex;
                    gap: 1.5rem;

                    .title-box{
                        width: 100%;
                        flex: 1;

                        h2{
                            font-family: reckless-middle;
                            font-size: 2.5rem;
                        }
                        p{
                            font-size: 0.875rem;
                            font-weight: 200;
                            text-transform: capitalize;
                        }
                    }
                    .info-box{
                        height: 11rem;
                        width: 100%;
                        flex: 1;
                        border-radius: 1rem;
                        border: 1px solid $white-20;


                        .minichart{
                            width: 100%;
                            height: 10rem;
                        }
                    }
                }
                .middle-row{
                    margin-top: 1.5rem;
                    height: 24rem;
                    cursor: pointer;
                    border-radius: 1rem;
                    border: 1px solid $white-20;

                    #main-bar-chart{
                        height: 100%;
                        width: 100%;
                    }
                }
                .bottom-row{
                    margin-top: 1.5rem;
                    height: 24rem;
                    border-radius: 1rem;
                    border: 1px solid $white-20;
                    overflow: hidden;
                }
            }
            .side-day{
                width: 25%;
                height: 95vh;
                flex: 1;
                padding: 2rem 1.5rem 1.5rem 1.5rem;
                border-radius: 1rem;
                border: 1px solid $white-20;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .day-info{
                    border: 0px solid peru;
                    .day-date{
                        color: $white-80;
                        font-size: 1.25rem;
                        font-family: reckless-middle;
                        margin-bottom: 0.5rem;
                    }
                    .day-line{
                        font-size: 0.875rem;
                        font-weight: 200;
                    }
                }
                .day-chart{
                    height: 36%;
                    width: 100%;
                    overflow: hidden;

                    #day-half-doughnut{
                        border: 0px solid peru;
                        position: relative;
                        cursor: pointer;
                        height: 100%;
                        width: 100%;

                        .watermark-cover{
                            border: 0px solid peru;
                            position: absolute;
                            z-index: 5000;
                            bottom: 0;
                            background: black;
                            width: 100%;
                            height: 2rem;
                        }

                        .score-box{
                            position: absolute;
                            bottom: 42%;
                            left: 50%;
                            transform: translate(-50%, 50%);

                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .total{
                                color: $white-50;
                                font-size: 0.875rem;
                            }

                            .day-main-score{
                                font-size: 1.5rem;
                            }
                        }
                    }
                }

                .day-subscores{
                    border: 0px solid peru;
                    width: 100%;
                    height: auto;
                    // padding: 6rem 0;
                    background: black;
                    // margin-top: -2rem;
                    z-index: 5000;

                    .subscore-grid{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 1rem;


                        .grid-item{
                            border: 0px solid pink;
                            width: 100%;
                            height: 6rem;

                            p{
                                color: $white-50;
                            }
                            .subscore-name{
                                text-transform: capitalize;
                                margin-bottom: 0.75rem;
                            }
                            .subscore-value{
                                color: $white;
                                margin-bottom: 0.5rem;
                            }
                            .subscore-bar-container{
                                height: 0.75rem;
                                border-radius: 0.35rem;
                                width: 100%;
                                background: $white-20;

                                .bar{
                                    height: 100%;
                                    width: 0;
                                    border-radius: 0.35rem;
                                    // transform-origin: left;
                                }
                            }
                        }
                        .grid-item.health .bar{
                            background: linear-gradient(90deg, #803242 -2.08%, #6C3961 100%);
                            
                        }
                        .grid-item.wealth .bar{
                            background: linear-gradient(90deg, #CF4A33 -2.08%, #C23D3F 100%);
                        }
                        .grid-item.happiness .bar{
                            background: linear-gradient(90deg, #F5C373 -2.08%, #E57F53 100%);
                        }
                        .grid-item.nodo .bar{
                            background: #646464;
                        }
                    }
                }

                .day-bottom-info{
                    margin-top: auto;
                    border: 0px solid green;
                    width: 100%;
                    height: 7rem;
                    display: flex;

                    .change-box{
                        border-right: 1px solid $white-20;
                        width: 100%;
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5rem;
                    }

                    .average-box{
                        width: 100%;
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5rem;
                    }

                    .title{
                        font-size: 0.875rem;
                    }

                    .value{
                        font-size: 1.25rem;
                        font-family: satoshi;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    #health-line-chart{
        height: 100%;
        width: 100%;
    }
    #happiness-line-chart{
        height: 100%;
        width: 100%;
    }
    #wealth-lin-chart{
        height: 100%;
        width: 100%;
    }
    #nodo-line-chart{
        height: 100%;
        width: 100%;
    }
    #progress-bar-chart{
        height: 100%;
        width: 100%;
    }


    .log-grid{
        height: 100%;
        width: 100%;
        border-radius: 1rem;

        .ag-theme-alpine-dark {
            --ag-background-color: $black;
            width: 100%;

            .ag-center-cols-container{
                width: 100px;
            }
        }
        .ag-header{
            background: $black;
        }
        // .ag-row:nth-child(odd){
        //     background: peru;
        // }
        .super-dark{
            background: $black;
        }
        .soft-dark{
            background: $black;
        }
    }
}


