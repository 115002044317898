

.introPage{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    
    overflow-x: hidden;
  
  
    .intro-bg{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 57%;
      width: 100vw;
      overflow: hidden;
  
      .background-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
  
      .dark-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
  
        .intro-nav{
          width: 100vw;
          border: 0xpx solid white;
          display: flex;
          justify-content: space-between;
          padding: 1rem 1.5rem;
    
          div{
            color: white;
            font-family: satoshi;
            font-size: 1rem;
            font-weight: 500;
          }
  
          .intro-skip{
            cursor: pointer;
          }
        }
      }
    }
  
  
    .intro-bottom{
      height: 43%;
      width: 100%;
      background: $black;
      border-top: 0.8px solid $button-line;
    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      h2{
        color: white;
        text-transform: capitalize;
      }
      p{
        color: $white-50;
        text-align: center;
        padding: 0 3rem;
        margin-top: 2rem;
      }
      .intro-button{
        height: 60px;
        width: 60px;
        background: $button-dark;
        border-radius: 1rem;
        border: 1px solid $button-line;
        margin-top: 3rem;
  
        display: grid;
        place-items: center;
        animation: pulse 3s linear infinite;
        transition: box-shadow 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  
        .arrow{
          height: 19px;
          width: 22px;
          background: url(../../public/icons/arrow.svg);
          background-size: cover;
          background-position: contain;
        }
      }
      .intro-button:hover{
        cursor: pointer;
        background: $button-line;
        box-shadow: 0 0 30px 0 white;
      }
    }
  }
  
  @keyframes pulse {
  
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 $button-line;
      border-color: $button-line;
    }
    50% {
      transform: scale(1.02);
      box-shadow: 0 0 10px 0 $button-line;
      border-color: lighten($button-line, 10%);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 $button-line;
      border-color: $button-line;
    }
  }
