

.page-top{
    width: 100%;
    padding: 4rem 1.5rem 1.5rem 3rem;
    border: 0px solid peru;
    display: flex;
    gap: 1.5rem;

    .info-box{
        border: 1px solid $white-20;
        height: 11rem;
        width: 100%;
        padding: 1.5rem;
        flex: 1;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .icon-container{
            border: 1px solid $white-20;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0.5rem;
            display: grid;
            place-items: center;
            margin-bottom: 1rem;

            .random-icon svg{
                width: 1rem;
                height: 1rem;
                opacity: 1;
                vertical-align: middle;
            }
            .random-icon.stroke.lower{
                opacity: 0.8;
            }
        }
        .box-title{
            font-weight: 200;
            font-size: 0.875rem;
            text-transform: capitalize;
            margin-bottom: 0.75rem;
        }
        .box-value{
            color: $white-80;
            font-size: 2rem;
            font-family: satoshi;
            font-weight: 300;
        }
    }
    .title-box{
        width: 100%;
        flex: 0.8;

        h2{
            font-family: reckless-middle;
            font-size: 2.5rem;
        }
        p{
            font-size: 0.875rem;
            font-weight: 200;
            text-transform: capitalize;
        }
    }
}


.health-page, .wealth-page, .happiness-page, .nodo-page, .progress-page{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .chart-container{
        padding: 0 1.5rem 1.5rem 3rem;
        width: 100%;
        height: 100%;

        .chart-box{
            border: 1px solid $white-20;
            height: 100%;
            border-radius: 1rem;
            overflow: hidden;

            #wealth-line-chart{
                width: 100%;
                height: 100%;
            }
        }
    }
}


